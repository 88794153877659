*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.ses-pointer-events-none {
  pointer-events: none;
}

.ses-invisible {
  visibility: hidden;
}

.ses-relative {
  position: relative;
}

.ses-col-span-1 {
  grid-column: span 1 / span 1;
}

.ses-col-start-1 {
  grid-column-start: 1;
}

.ses-row-span-1 {
  grid-row: span 1 / span 1;
}

.ses-row-start-1 {
  grid-row-start: 1;
}

.ses-grid {
  display: grid;
}

.ses-h-full {
  height: 100%;
}

.ses-max-h-screen {
  max-height: 100vh;
}

.ses-w-full {
  width: 100%;
}

.ses-max-w-none {
  max-width: none;
}

.ses-items-start {
  align-items: flex-start;
}

.ses-justify-center {
  justify-content: center;
}

.ses-self-start {
  align-self: flex-start;
}

.ses-justify-self-center {
  justify-self: center;
}

.ses-overflow-hidden {
  overflow: hidden;
}

.ses-object-cover {
  object-fit: cover;
}

.animation-delay-2 {
  ses-animation-delay: .2s;
}

.animation-delay-4 {
  ses-animation-delay: .4s;
}

.opacity-gradient {
  -webkit-mask-image: linear-gradient(#0000 0%, 6%, #000, 94%, #0000 100%);
}

/*# sourceMappingURL=index.css.map */
