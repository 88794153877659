@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

/* --------- Utilities --------- */
.animation-delay-2 {
  ses-animation-delay: 0.2s;
}

.animation-delay-4 {
  ses-animation-delay: 0.4s
}

.opacity-gradient {
  -webkit-mask-image: linear-gradient(to bottom, transparent 0%, 6%, black, 94% , transparent 100%);
}
